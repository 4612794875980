import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class UnAuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean { 
    if(localStorage.getItem('auth_token') !== null) {
      this.router.navigate(["dashboard"]);
      return false;
    }

    return true;
  }
}