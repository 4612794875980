import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  darkMode = false;
  constructor() {
      console.log('Version - 1.3.1');
      
      let isDark = localStorage.getItem('dark_mode');
      if(isDark == 'true'){
        localStorage.setItem('dark_mode', 'true');
        this.darkMode = true;
      }else{
        localStorage.setItem('dark_mode', 'false');
        this.darkMode = false;
      }
      document.body.classList.toggle('dark', this.darkMode);
    }


}
